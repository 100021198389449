@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Inter:wght@400;700&display=swap');


html {
  scroll-behavior: smooth;
}
body {
  @apply font-roboto; 
}


